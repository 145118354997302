.tag {
  color: #a9acb3;
  background-color: rgba(#a9acb3, 0.2);
  padding: 0px 6px;
  display: inline-block;
  border-radius: 3px;

  &.yellow {
    background-color: rgba(#cc8b37, 0.2);
    color: #cc8b37;
  }

  &.red {
    background-color: rgba(#d9534f, 0.2);
    color: #d9534f;
  }

  &.cyan {
    background-color: rgba(#1f9bcf, 0.2);
    color: #1f9bcf;
  }
}
