.notifBtn {
  color: var(--bs-secondary); // default icon color
  background-color: transparent;
  position: relative;
  z-index: 0; // for notif blink
  border: none;
  font-size: 1.6rem;

  .notifCount {
    // height: 1.2rem;
    min-width: 1.5rem;
    padding: 0 0.3rem;
    font-size: 10px;
    color: white;
    border-radius: 10rem;
    position: absolute;
    top: 0;
    left: 50%;
    transition: all 0.25s;

    /* blinking border */
    &.blink::before {
      content: " ";
      // height: 1.2rem;
      // width: 1.5rem;
      background: rgb(0 0 0 / 100%);
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      border-radius: 45%;
      z-index: -1;
      box-shadow: 0px 0px 8px 5px rgb(210 210 210 / 60%);
      &:global {
        animation: spinner-grow 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
      }
    }
  }

  &:hover .notifCount {
    /* Do not use transform:translateY, which will lose z-index and make pseudo-element (blinking object) on top. [https://stackoverflow.com/questions/20851452/z-index-is-canceled-by-setting-transformrotate] */
    top: -0.2rem;
  }

  /* icon */
  & > svg {
    font-size: 1.5rem;
  }
}
