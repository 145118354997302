.breadcrumbs {
  list-style: none;
  padding: 0;
  margin-bottom: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  row-gap: 0;
}

.crumb {
  font-size: 0.9rem;

  & > * {
    color: var(--bs-body-color);
  }

  & > *:hover {
    color: inherit;
  }

  &:not(:last-child)::after {
    content: ">";
    margin-inline-start: 0.5rem;
  }

  &:last-child > * {
    color: var(--bs-dark);
    text-decoration: none;
    cursor: default;
  }
}
