.wrap {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  display: flex;
  flex: auto;
  position: relative;

  &.mobile {
    flex-direction: column;
    position: relative;
  }
}

.sidebar {
  width: 200px;
  position: fixed;
  top: 64px;
  bottom: 0;
  z-index: 500;
  transition: all 0.3s;

  // box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  &.close {
    transform: translateX(-100%);
  }

  &.mobile {
    width: 100vw;
    height: calc(100vh - 65px); //65px is Header's height
  }
}

.content {
  /**
  * padding top: distance to nav header
  * padding left|right: reserved for collapsible sidebar icon
  * padding bottom: distance to footer
  */
  padding: 1rem 2.5rem 4rem;
  margin-top: 65px;
  min-width: 0;
  flex: auto;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  margin-left: 0;
  position: relative;

  &.shrink {
    padding: 1rem 1rem 4rem;
    margin-left: 200px;

    @media (max-width: 768px) {
      margin-left: 0px;
    }
    @media (max-width: 390px) {
      padding-inline: 0;
    }
  }

  @media (max-width: 768px) {
    padding: 1rem 1rem 4rem;
  }

  @media (max-width: 390px) {
    padding-inline: 0;
  }
}
