/*
Target: Button
*/
.ant-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.ant-btn-primary {
  //Fix the weird white box-shadow in dark mode (which is caused by colorBgBase token)
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);

  // &:disabled {
  //   color: var(--bs-dark);
  //   opacity: 0.5;
  // }
}

/*
Target: Card
Action: dark mode
*/
// .ant-card {
//   background-color: var(--bs-white);
//   color: var(--bs-dark);

//   .ant-card-head {
//     border-color: var(--bs-gray-200);
//     .ant-card-head-title {
//       color: var(--bs-dark);
//     }
//   }

//   &.ant-card-hoverable:hover {
//     box-shadow: 0 1px 2px -2px rgb(255 255 255 / 16%),
//       0 3px 6px 0 rgb(255 255 255 / 12%), 0 5px 12px 4px rgb(255 255 255 / 9%);
//   }
// }

/*
Target: Card / Action Footer
Action: set footer background color
*/
.ant-card {
  &.antx-card-actions-load-more {
    .ant-card-actions {
      background-color: var(--bs-gray-100);
    }
  }

  .ant-card-body {
    scrollbar-color: var(--bs-gray-200) var(--bs-black);

    scrollbar-width: thin;
    /* custom scroll bar */
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-track {
      background: var(--bs-black);
    }

    &::-webkit-scrollbar-thumb {
      background: var(--bs-gray-200);
      border-radius: 10px;
      border: 2px solid transparent;
      background-clip: content-box;
    }
  }
}

/*
Target: DatePicker / DatePicker
Action: support small screen (xs) picker (allow overflow to show full picker panel)
*/
.antx-datepicker-dropdown {
  left: auto !important;
  width: 100%;

  .ant-picker-panel-container {
    overflow: auto;
  }

  [class^="ant-picker-"][class$="-panel"],
  .ant-picker-content {
    width: 100% !important;
  }
}

/*
Target: DatePicker / DatePicker
Action: hide footer
*/
.antx-datepicker-hide-footer {
  .ant-picker-ranges {
    display: none;
  }
}

/*
Target: DatePicker / DatePicker
Action: Display datepicker in modal with full width and height. The class inheritance is important to some extend, which determing CSS priority.
*/
.antx-datepicker-inside-modal {
  position: relative;
  .ant-picker-panel-container {
    box-shadow: none;
    .ant-picker-panel {
      overflow: auto;
      width: 100%;

      [class^="ant-picker-"][class$="-panel"] {
        width: 100%;
        .ant-picker-content {
          width: 100%;
        }
      }
      .ant-picker-time-panel {
        /* avoid time picker to expand */
        flex: 0;
      }
      .ant-picker-date-panel {
        /* avoid date picker to overlap with time picker */
        min-width: 300px;
      }
    }
    .ant-picker-footer {
      position: sticky;
      left: 0;
    }
  }
}

/*
Target: DatePicker / RangePicker
Action: support small screen picker (allow overflow to show full picker panel)
*/
.antx-rangepicker-dropdown {
  left: 5vw !important;
  width: 90vw;

  .ant-picker-panel-container {
    overflow: auto;

    .ant-picker-date-panel {
      width: 100%;
      .ant-picker-content {
        width: 100%;
      }
    }
  }
}

/*
Drawer
*/
.ant-drawer {
  .ant-drawer-content-wrapper:has(.ant-drawer-content.antx-no-animation) {
    transition: none;
  }
}
/*
Target: Icons
Action: adjust icon to higher alignment because the default vertical alignment is too low.
Note: This change make some icons looks better but others become wierd. So use this carefully.
*/
// .anticon {
//   vertical-align: 0.05em;
// }

/*
Target: Input
Action: color of input's & placeholder's text
*/
.ant-input {
  &.ant-input-borderless {
    color: var(--bs-dark);
  }
  &::placeholder {
    color: var(--bs-gray-500);
  }
}

/*
Target: Input / TextArea
Action: color of textarea's clear icon & word count
*/
.ant-input-textarea {
  .ant-input-clear-icon {
    color: var(--bs-gray-500);
  }
  .ant-input-data-count {
    color: var(--bs-secondary);
  }
}
/*
Target: Menu
*/
.ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline {
  background-color: transparent;
}
/*
Target: Modal
*/
.ant-modal .ant-modal-content {
  padding-inline: 16px;
}

/*
Target: Pagination
*/
.ant-pagination {
  .ant-pagination-item-active {
    background-color: var(--bs-gray-300);
    border: none;
  }
}

/*
Target: Progress
Action: smaller border radius of progress bar
*/
.ant-progress {
  &.antx-progress-bar-shape {
    .ant-progress-inner,
    .ant-progress-bg {
      border-radius: 10px;
    }
  }
}

/*
Target: Progress
Action: dark mode
*/
// .ant-progress {
//   .ant-progress-text {
//     color: var(--bs-dark);
//   }
// }

/*
Target: Select
Action: dark mode
*/
// .ant-select {
//   .ant-select-selection-item {
//     color: var(--bs-dark);
//   }

//   &.ant-select-single.ant-select-lg.ant-select-open .ant-select-selection-item {
//     color: var(--bs-dark) !important;
//   }
// }

/*
Target: Select / Dropdown
Action: (dark mode) text color of the selected item
*/
// .ant-select-dropdown {
//   // background-color: var(--bs-gray-100);

//   .ant-select-item-option-selected {
//     // color: var(--bs-dark) !important;
//     // background-color: var(--theme-blue-selected) !important;
//   }
// }

/*
Target: Select / RangePicker
Action: support small screen picker (allow overflow to show full picker panel)
*/
.antx-select-label-width-5vw {
  .ant-select-selection-item {
    width: 20vw;
  }
}

/*
Target: Statistic
Action: dark mode
*/

// .ant-statistic {
//   .ant-statistic-content {
//     color: var(--bs-dark);
//   }

//   .ant-statistic-title {
//     color: var(--bs-dark);
//   }
// }

/*
Target: Tabs
Action: decrease contrast of tab text
*/
.ant-tabs-nav {
  & .ant-tabs-tab-btn {
    color: var(--bs-gray-600);
  }
}

/*
Target: Timeline
*/
.ant-timeline {
  .ant-timeline-item {
    .ant-timeline-item-tail {
      border-color: var(--bs-gray-200);
    }
  }
}

/*
Target: Table
*/
.ant-table {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}
.ant-table .ant-table-content,
.ant-table .ant-table-body {
  scrollbar-color: var(--bs-gray-200) var(--bs-black);

  scrollbar-width: thin;
  /* custom scroll bar */
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background: var(--bs-black);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--bs-gray-200);
    border-radius: 10px;
    border: 2px solid transparent;
    background-clip: content-box;
  }
}

/*
Target: Table
Action: Prevent tooltip (absolute position) from exceeding the table region
*/
.ant-table .ant-table-body {
  position: relative;
}

/*
Target: Transfer
Action: Temporary fix for svg vertical align overwrite by Bootstrap
*/
// .ant-transfer svg {
//   vertical-align: baseline !important;
// }
